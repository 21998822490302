import Api from "@/core/apis";

export default {
  getInfobox(url) {
    return Api().get("/url-info", { params: { url } });
  },
  updateInfobox(data) {
    return Api().post("/url-info", data);
  }
};
