<template>
  <div class="px-5">
    <span class="font-weight-bold">Khai báo SEO & Info box</span>

    <v-card class="mt-5" flat>
      <div class="pa-4">
        <div class="font-weight-bold">Nhập trang cần khai báo</div>

        <div class="mt-4" style="max-width: 500px">
          <tp-text-field v-model="path" placeholder="Nhập url"></tp-text-field>
        </div>
      </div>

      <div v-if="seoData" class="pa-4">
        <div class="pa-4 grey lighten-3 black--text">
          <div class="mb-4 flex justify-space-between">
            <div class="flex flex-wrap">
              <v-btn
                v-for="tab in ARRAY_LIST_MODEL_SITES"
                :key="tab.id"
                :color="currentTab === tab.id ? 'primary' : 'white'"
                class="mr-2"
                depressed
                small
                @click="currentTab = tab.id"
              >
                {{ tab.text }}
              </v-btn>
            </div>

            <v-btn
              :disabled="isLoading"
              :loading="isLoading"
              class="rounded-lg"
              color="primary"
              depressed
              @click="handleUpdate"
            >
              Lưu
            </v-btn>
          </div>

          <v-card v-if="seoData" class="px-5 py-4" flat>
            <div class="font-weight-bold mb-2">Thông tin SEO</div>

            <div>
              <form-seo-data-item
                v-model="seoData[currentTab].defaultSeo"
              ></form-seo-data-item>
            </div>

            <div>
              <v-checkbox
                v-model="seoData[currentTab].isSchedule"
                class="font-weight-bold"
                label="Hẹn giờ"
              ></v-checkbox>

              <div v-if="seoData[currentTab].isSchedule">
                <form-seo-data-item
                  v-model="seoData[currentTab].scheduleSeo"
                  schedule
                ></form-seo-data-item>
              </div>
            </div>
          </v-card>

          <v-card class="mt-5 px-5 py-4" flat>
            <div class="font-weight-bold mb-2">Infobox</div>

            <ThinkTiptap
              v-model="content[currentTab]"
              :config="config"
              class="tp-tiptap"
            ></ThinkTiptap>
          </v-card>
        </div>
      </div>

      <div v-else class="pa-4">
        <div class="pa-10 grey lighten-3 black--text text-center">
          <span class="font-weight-bold text-h5"
            >Vui lòng nhập URL để khai báo</span
          >
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { defineComponent, ref, getCurrentInstance } from "vue";
import { debouncedWatch } from "@vueuse/core";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import FormSeoDataItem from "@/core/components/form/FormSeoData/FormSeoDataItem.vue";
import {
  ARRAY_LIST_MODEL_SITES,
  INIT_SITE_CONFIG_SEO_DATA
} from "@/core/constant";
import {
  serialize,
  deserialize
} from "@/core/components/form/FormSeoData/utils";
import inforboxService from "@/modules/Website/service/inforbox.service";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";

export default defineComponent({
  components: { FormSeoDataItem },

  setup() {
    const instance = getCurrentInstance();
    const { getInfobox, updateInfobox } = inforboxService;
    const currentTab = ref("THINKPRO");

    const path = ref("");
    const seoData = ref(null);
    const content = ref(null);

    const { fetch } = useFetchData(() => getInfobox(path.value), {
      transform: response => response.data.data,
      enabled: () => Boolean(path.value),
      initialData: () => {
        return {
          seo_data: INIT_SITE_CONFIG_SEO_DATA,
          info_box: { THINKPRO: "", GOODSPACE: "" }
        };
      },
      resolve: data => {
        seoData.value = serialize(data.seo_data, currentTab.value);
        content.value = data.info_box;
      }
    });

    debouncedWatch(path, fetch, { debounce: 300 });

    const { mutate: handleUpdate, isLoading } = useMutation(
      () =>
        updateInfobox({
          url: path.value,
          seo_data: deserialize(seoData.value),
          info_box: content.value
        }),
      {
        onSuccess: () => {
          showToastActionAlert(instance.proxy, { message: "Đã lưu thay đổi" });
        },
        onError: () => {
          showModalAlertError(instance.proxy, {
            title: "Không thể lưu cập nhật",
            message:
              "Dữ liệu nhập vào không hợp lệ, vui lòng kiểm tra và thử lại."
          });
        }
      }
    );

    return {
      currentTab,
      seoData,
      content,
      path,
      handleUpdate,
      isLoading
    };
  },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES,
      config: {
        baseURL: process.env.VUE_APP_THINKPRO_FRONT_STORE,
        imageUploadFolder: "core/site-configs",
        sites: ["THINKPRO", "GOODSPACE"]
      }
    };
  }
});
</script>
